import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mapShimmer"];
  async connect() {
    try {
      const companyId = this.element.dataset.mapMap;
      const companyData = await this.getCompanyData(companyId);
      this.initHeatMap(companyData);
    } catch (error) {
      console.error("Error initializing heatmap:", error);
    }
  }

  async getCompanyData(companyId) {
    try {
      const response = await fetch("/search/map_view_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ company_id: companyId }),
      });
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching company data:", error);
      return [];
    }
  }

  initHeatMap(companyData) {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxleHRvcGxpbmUiLCJhIjoiY2x5NjFobzl4MDVvZTJrcHgxODY1YjJlNiJ9.2Ubqk43wiSfQTnlVya0W4w";
    const mapContainer = this.element.querySelector('div[id^="__flat_map"]');
    const map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-79.999732, 40.4374],
      zoom: 0,
      projection: "mercator",
    });

    if (this.mapShimmerTarget) {
      this.mapShimmerTarget.classList.add('hidden');
    }
    
    map.on("load", () => {
      const geojsonData = this.convertToGeoJSON(companyData);

      map.addSource("people", {
        type: "geojson",
        data: geojsonData,
      });
      map.addLayer({
        id: "people-heatmap",
        type: "heatmap",
        source: "people",
        maxzoom: 15,
        paint: {
          "heatmap-weight": ["interpolate", ["linear"], ["get", "density"], 0, 0, 6, 1],
          "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 11, 1, 15, 3],
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
              0, "rgba(255, 255, 255, 0)",
              0.2, "rgba(255, 200, 200, 0.6)",
              0.4, "rgba(255, 150, 150, 0.6)",
              0.6, "rgba(255, 100, 100, 0.8)",
              0.8, "rgba(255, 50, 50, 0.9)", 
              1, "rgba(255, 0, 0, 1)"
            ],
          "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 11, 15, 15, 20],
          "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 14, 1, 15, 0],
        },
      });

      const bounds = new mapboxgl.LngLatBounds();

      companyData.forEach(coords => {
        bounds.extend([coords[1], coords[0]]);
      });

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, {
          padding: 20,
          maxZoom: 15,
          duration: 2000,
        });
      }

    });
  }

  convertToGeoJSON(companyData) {
    return {
      type: "FeatureCollection",
      features: companyData.map((coords) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [coords[1], coords[0]],
        },
        properties: {
          density: 1,
        },
      })),
    };
  }
}
