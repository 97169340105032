import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  connect() {
    const locationsData = this.data.get("locations");

    if (!locationsData) {
      console.error("No locations data found.");
      return;
    }

    tippy(this.element, {
      content: () => this.content(this.data.get("locations")),
      allowHTML: true,
      arrow: true,
      placement: "right",
      interactive: true,
    });
  }

  content(locationsData) {
    const locations = JSON.parse(locationsData);
    return `
      <div class=" bg-white rounded-[10px]">
        <ul class="list-none inline-flex flex-wrap gap-[10px] m-0 shadow-shadow_two rounded-[10px] p-[12px]">
          ${locations.map((location) =>
            `<li> <span class='experencise-tag'>${location.trim()}</span>  </li>`
          ).join("")}
        </ul>
      </div>
    `;
  }
}
